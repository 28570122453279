/* You can add global styles to this file, and also import other style files */
@import 'color-variables.scss';
@import 'custom-material-theme';
@import '~flickity/dist/flickity.min.css';

@font-face {
    font-family: 'SFProText';
    src:
        url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.ttf') format('ttf'),
        url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.woff') format('woff'),
        url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.woff2') format('woff2'),
        url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.svg') format('svg'),
        url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.eot') format('eot');
}

@font-face {
    font-family: 'SFProText-light';
    src:
        url('/assets/fonts/SFProText-Light/SF Pro Text Light.ttf') format('ttf'),
        url('/assets/fonts/SFProText-Light/SF Pro Text Light.woff2') format('woff2'),
        url('/assets/fonts/SFProText-Light/SF Pro Text Light.svg') format('svg'),
        url('/assets/fonts/SFProText-Light/SF Pro Text Light.eot') format('eot');
}

@font-face {
    font-family: 'SFProText-Bold';
    src:
        url('/assets/fonts/SFProText-Bold/SF Pro Text Bold.ttf') format('ttf'),
        url('/assets/fonts/SFProText-Bold/SF Pro Text Bold.woff2') format('woff2'),
        url('/assets/fonts/SFProText-Bold/SF Pro Text Bold.svg') format('svg'),
        url('/assets/fonts/SFProText-Bold/SF Pro Text Bold.eot') format('eot');
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
}

html,
body {
    height: 100%;
    touch-action: manipulation;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto';
    margin: auto;
}

mat-error {
    /* Android/Body/Medium */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

.no-zoom {
    touch-action: manipulation; /* This will prevent double-tap zoom */
}
// .flickity-viewport {
//     height: 289px;
//     //width: calc(100vw - 32px);
//     //margin-left: 16px;
// }

/* no circle */
.flickity-button {
    background: transparent;
    .flickity-button-icon {
        height: 16px;
    }
}
/* big previous & next buttons */
.flickity-prev-next-button {
    width: 64px;
    height: 64px;
}
.flickity-prev-next-button.next {
    right: -13px;
}
.flickity-prev-next-button.previous {
    left: -13px;
}
/* icon color */
.flickity-button-icon {
    fill: $primary;
}
/* hide disabled button */
.flickity-button:disabled {
    display: none;
}
.flickity-button:focus {
    outline: none;
    box-shadow: none;
}
.flickity-button:hover {
    background: none;
    box-shadow: none;
}
