$red: #e94366;
$gradient-yellow-red: linear-gradient(104.04deg, #febe76 0%, #f24b68 100%);
$black: #000;
$white: #ffffff;
$white-smoke-color: #f5f5f5;
$yellow: #fece6c;
$green: #a0d988;
$border-weakest: #ccc;
$primary: #262626;
$surface-brand-weak: #9e9e9e;
$surface-destructive: #f44336;
$text-brand-link: #007aff;
$mood-bg: #f0f0f0;
$gray-gray-7: #dfdfdf;

// TODO our target to use css native root variables
:root {
    --Primary: #262626;
    --gray-gray-5: #ccc;
    --black: #000;
    --white: #ffffff;
    --red-red: #e94366;
    --yellow-yellow: #fece6c;
    --green-green: #a0d988;
    --gradient-yellow-red: linear-gradient(104deg, #febe76 0%, #f24b68 100%);
    --grays-gray-7: #dfdfdf;
    --grays-gray-8: #7d7d7d;
    --surface-brand-weak: #9e9e9e;
    --borders-border-default: #262626;
    --bs-body-color: #262626;
    --mdc-outlined-text-field-input-text-color: #262626;
}
